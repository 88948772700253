.NavbarSmall {
  overflow_: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 99;
  opacity: 1.0;
  background-color: #46A969;

}

.NavbarBig {
  overflow_: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 99;
  opacity: 0.95;
  min-height: 60px;
}

@media all and  (max-width: 991px) {
  #Test{
    color: white;
    font-weight: bold;
    background-color: #46A969;
    padding: 10px;
  }
}